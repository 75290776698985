import { getPayloadObject, getTelemetryAttributes, IPayLoad, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IQuantityProps } from './Quantity.props';

interface IQuantityState {
    currentInput: number;
    isUpdateing: boolean;
}
/**
 * Quantity Component - This component is used to add or remove quantity
 */
@observer
export default class Quantity extends React.PureComponent<IQuantityProps, IQuantityState> {
    public static defaultProps: Partial<IQuantityProps> = {
        min: 1,
        decrementGlyphClass: 'fas fa-minus',
        incrementGlyphClass: 'fas fa-plus'
    };

    private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private payLoad: IPayLoad;

    constructor(props: IQuantityProps) {
        super(props);
        this.state = { currentInput: props.currentCount || 1, isUpdateing: false };
        this._onIncrement = this._onIncrement.bind(this);
        this._onDecrement = this._onDecrement.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
    }

    public componentDidUpdate(prevProps: IQuantityProps): void {
        if (
            !this.props.disabled &&
            this.state.currentInput !== this.props.currentCount &&
            prevProps.currentCount !== this.props.currentCount
        ) {
            this.setState({ currentInput: this.props.currentCount || 1 });
        }

        if (this.props.disabled && !this.state.isUpdateing) {
            this.setState({ isUpdateing: true });
        }

        if (!this.props.disabled && this.state.isUpdateing) {
            this.setState({ currentInput: this.props.currentCount || 1, isUpdateing: false });
        }
    }

    public render(): JSX.Element {
        const { min, max } = this.props;

        const glyphMinusClassName: string = `${this.props.decrementGlyphClass!} quantity__controls-glyph`;
        const glyphPlusClassName: string = `${this.props.incrementGlyphClass!} quantity__controls-glyph`;
        const decrementDisabled = this.state.currentInput <= (min || 1) || this.props.isGiftCard;
        const incrementDisabled = this.state.currentInput >= max || this.props.isGiftCard;
        const currentValue = this.state.currentInput;
        this.payLoad.contentAction.etext = TelemetryConstant.DecrementQuantity;
        const decrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        this.payLoad.contentAction.etext = TelemetryConstant.IncrementQuantity;
        const incrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);

        let extraClassDecrement = '';
        if (decrementDisabled) {
            // The quantity has reached its boundaries (max or min)
            extraClassDecrement = 'disabled';
        } else if (this.props.disabled) {
            // this.props.disabled shows if the state is not succeded yet
            extraClassDecrement = 'transition';
        }
        let extraClassIncrement = '';
        if (incrementDisabled) {
            // The quantity has reached its boundaries (max or min)
            extraClassIncrement = 'disabled';
        } else if (this.props.disabled) {
            // this.props.disabled shows if the state is not succeded yet
            extraClassIncrement = 'transition';
        }

        return (
            <>
                <div className='quantity' id={this.props.id}>
                    <button
                        disabled={this.props.disabled || decrementDisabled}
                        title={decrementDisabled ? '' : this.props.decrementButtonAriaLabel}
                        className={`decrement quantity__controls ${extraClassDecrement}`}
                        onClick={this._onDecrement}
                        aria-hidden={true}
                        aria-label={`${this.props.decrementButtonAriaLabel}`}
                        tabIndex={-1}
                        color={'secondary'}
                        {...decrementAttributes}
                    >
                        <span className={glyphMinusClassName} />
                    </button>
                    <input
                        type='number'
                        className='quantity-input'
                        pattern='[0-9]*'
                        value={this.state.currentInput}
                        onChange={this._handleChange}
                        onBlur={this._validateMin}
                        aria-live='polite'
                        aria-label={`${this.props.inputQuantityAriaLabel}`}
                        role='spinbutton'
                        aria-valuemin={min}
                        aria-valuemax={max}
                        aria-valuenow={currentValue}
                        ref={this.inputRef}
                        disabled={this.props.disabled}
                    />
                    <button
                        disabled={this.props.disabled || incrementDisabled}
                        title={incrementDisabled ? '' : this.props.incrementButtonAriaLabel}
                        className={`increment quantity__controls ${extraClassIncrement}`}
                        onClick={this._onIncrement}
                        aria-hidden={true}
                        aria-label={`${this.props.incrementButtonAriaLabel}`}
                        tabIndex={-1}
                        color={'secondary'}
                        {...incrementAttributes}
                    >
                        <span className={glyphPlusClassName} />
                    </button>
                </div>
            </>
        );
    }

    private _onIncrement(): void {
        let invokeCallback = false;
        const currQuantity = this.state.currentInput;
        let updatedQuantity: number;

        if (currQuantity < this.props.max) {
            invokeCallback = true;
            updatedQuantity = currQuantity + 1;
        } else {
            invokeCallback = false;
            updatedQuantity = this.props.max;
        }

        if (invokeCallback && this.props.onChange) {
            if (this.props.onChange(updatedQuantity)) {
                this.setState({ currentInput: updatedQuantity });
            }
        }
    }

    private _onDecrement(): void {
        let invokeCallback = false;
        const currQuantity = this.state.currentInput;
        let updatedQuantity: number;

        if (currQuantity > 1) {
            invokeCallback = true;
            updatedQuantity = currQuantity - 1;
        } else {
            invokeCallback = false;
            updatedQuantity = 1;
        }

        if (invokeCallback && this.props.onChange) {
            if (this.props.onChange(updatedQuantity)) {
                this.setState({ currentInput: updatedQuantity });
            }
        }
    }

    private _handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const currentValue = parseInt(e.target.value, 10);
        const minValue = this.props.min === undefined ? 1 : this.props.min;
        const inputElement =
            this.inputRef && this.inputRef.current && this.inputRef.current instanceof HTMLInputElement && this.inputRef.current;

        if (currentValue > this.props.max) {
            this.setState({ currentInput: this.props.max }, () => {
                debounce(() => {
                    // due of usage debouncer, we may ignore onChange return value
                    this.props.onChange && this.props.onChange(this.state.currentInput);
                }, 200)();
            });
        } else {
            this.setState({ currentInput: currentValue }, () => {
                debounce(() => {
                    if (!isNaN(this.state.currentInput) && !(this.state.currentInput < minValue)) {
                        // due of usage debouncer, we may ignore onChange return value
                        this.props.onChange && this.props.onChange(this.state.currentInput);

                        if (inputElement) {
                            inputElement.setAttribute('aria-valuenow', currentValue.toString());
                            inputElement.setAttribute('value', currentValue.toString());
                        }
                    }
                }, 200)();
            });
        }
    }

    private _validateMin = (): void => {
        const minValue = this.props.min === undefined ? 1 : this.props.min;
        if (isNaN(this.state.currentInput) || this.state.currentInput < minValue) {
            this.setState({ currentInput: minValue }, () => {
                this.props.onChange && this.props.onChange(this.state.currentInput);
            });
        } else {
            this.props.onChange && this.props.onChange(this.state.currentInput);
        }
    };
}
